import { on } from 'delegated-events';
import { focusable } from 'tabbable';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import { Instance, Placement } from '@popperjs/core';
import { createPopper } from '@popperjs/core/lib/popper-lite';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import offset from '@popperjs/core/lib/modifiers/offset';
import invisibleFocus from '../utils/invisibleFocus';

// Global state
let focusTrap: FocusTrap;
let popper: Instance;

// Flyout
on('click', 'button.u-flyout-indicator', (event) => {
  const { currentTarget: $trigger } = event;
  const isOpen = $trigger.getAttribute('aria-expanded') === 'true';

  // Is attached?
  const $parent = $trigger.parentNode;
  if (!$parent || !($parent instanceof HTMLElement)) {
    return;
  }

  // Get target
  const target = $trigger.getAttribute('aria-controls');
  if (!target) {
    return;
  }

  const $target = document.getElementById(target);
  if (!$target) {
    return;
  }

  if (!isOpen) {
    $target.hidden = false;
    $trigger.setAttribute('aria-expanded', 'true');

    const $flyoutRoot = $trigger.closest('[data-flyout-root]');
    const { flyoutPlacement = 'bottom', flyoutBoundary } = $target.dataset;

    popper = createPopper($trigger, $target, {
      placement: flyoutPlacement as Placement,
      modifiers: [
        {
          ...preventOverflow,
          options: {
            boundary: flyoutBoundary ?? $flyoutRoot ?? $trigger.parentElement ?? 'document',
          },
        },
        { ...offset, options: { offset: [0, 15] } },
      ],
    });

    focusTrap = createFocusTrap($parent, {
      onDeactivate() {
        $target.hidden = true;
        $trigger.setAttribute('aria-expanded', 'false');
        popper?.destroy();
        invisibleFocus($trigger);
      },
      onActivate() {
        const focusableItems = focusable($target);

        if (focusableItems[0]) {
          invisibleFocus(focusableItems[0]);
        }
      },
      initialFocus: false,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false,
    });

    focusTrap.activate();
  } else {
    focusTrap?.deactivate();
  }
});
