import { on } from 'delegated-events';
import { FocusTrap, createFocusTrap } from 'focus-trap';
import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import abort from '../../../javascripts/utils/abort';
import moveFocus from '../../../javascripts/utils/moveFocus';

let focusTrap: Maybe<FocusTrap>;
let menu: Maybe<A11yDialog>;

on('animationend', '.satellite-header__navigation', () => {
  menu?.destroy();
});

on('click', '.satellite-header__menu button.satellite-header__button', (event) => {
  const { currentTarget: $button } = event;
  const menuId = $button.getAttribute('aria-controls') ?? abort();
  const $menu = document.getElementById(menuId) ?? abort();

  // Kill existing instances before
  if (menu) {
    menu.destroy();
  }

  event.preventDefault();

  menu = new A11yDialog($menu);

  menu.on('show', () => {
    $menu.classList.add('satellite-header__navigation--open');
    disableBodyScroll($menu);
    moveFocus($menu);
  });

  menu.on('hide', () => {
    $menu.classList.remove('satellite-header__navigation--open');
    enableBodyScroll($menu);
    moveFocus($button);
    menu?.destroy();
  });

  menu.on('destroy', () => {
    menu = null;
  });

  menu.show();
});

on('click', '.satellite-header__navigation-link[aria-controls]', (event) => {
  const { currentTarget: $link } = event;
  const isOpen = $link.getAttribute('aria-expanded') === 'true';
  const submenuId = $link.getAttribute('aria-controls') ?? abort();
  const $submenu = document.getElementById(submenuId) ?? abort();

  event.preventDefault();

  if (!isOpen && $submenu.offsetParent) {
    return;
  }

  if (!isOpen) {
    focusTrap?.deactivate();
    focusTrap = createFocusTrap([$submenu, $link], {
      onActivate() {
        $link.setAttribute('aria-expanded', 'true');
        moveFocus($submenu);
      },
      onPostDeactivate() {
        $link.setAttribute('aria-expanded', 'false');
        $submenu.hidden = true;
        moveFocus($link);
        focusTrap = null;
      },
      initialFocus: false,
      returnFocusOnDeactivate: false,
      clickOutsideDeactivates: true,
      preventScroll: true,
    });

    $submenu.hidden = false;
    focusTrap.activate();
  } else {
    focusTrap?.deactivate();
  }
});
