import { on } from 'delegated-events';
import abort from '../../../javascripts/utils/abort';

on('click', '.js-show-external-content', (event) => {
  event.preventDefault();

  // Get container
  const { currentTarget: $trigger } = event;
  const $externalContent = $trigger.closest('.external-content') ?? abort();
  const $container = $externalContent.querySelector<HTMLElement>('.external-content__container') ?? abort();

  // Delete preview
  const $preview = $container.querySelector('.external-content__preview');
  $preview?.remove();

  // Unpack template with content
  const $content = $externalContent.querySelector<HTMLTemplateElement>('.external-content__content') ?? abort();
  $container.appendChild($content.content.cloneNode(true));

  // Find iframe
  const $iframe = $container.querySelector<HTMLIFrameElement>('iframe');
  if ($iframe && $iframe.width && $iframe.height) {
    $iframe.classList.add('external-content__iframe');
    $container.style.paddingTop = `${(parseInt($iframe.height, 10) / parseInt($iframe.width, 10)) * 100}%`;
  }
});
