import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import whenReady from '../../../javascripts/utils/whenReady';
import moveFocus from '../../../javascripts/utils/moveFocus';
import elementIndex from '../../../javascripts/utils/elementIndex';
import abort from '../../../javascripts/utils/abort';

const switchTab = ($oldTab: HTMLElement, $newTab: HTMLElement) => {
  // Make the active tab focusable by the user (Tab key)
  $newTab.removeAttribute('tabindex');

  // Focus to new tab
  invisibleFocus($newTab);

  // Set the selected state
  $newTab.setAttribute('aria-selected', 'true');
  $oldTab.removeAttribute('aria-selected');
  $oldTab.setAttribute('tabindex', '-1');

  // Hide old panel
  const oldPanelId = $oldTab.getAttribute('href') ?? abort();
  const $oldPanel = document.getElementById(oldPanelId.substring(1)) ?? abort();

  $oldPanel.querySelector('.navigator__panel-content')?.setAttribute('aria-hidden', 'true');
  $oldPanel.querySelector('.navigator__panel-trigger')?.setAttribute('aria-expanded', 'false');

  // Show old panel
  const newPanelId = $newTab.getAttribute('href') ?? abort();
  const $newPanel = document.getElementById(newPanelId.substring(1)) ?? abort();

  $newPanel.querySelector('.navigator__panel-content')?.setAttribute('aria-hidden', 'false');
  $newPanel.querySelector('.navigator__panel-trigger')?.setAttribute('aria-expanded', 'true');
};

on('click', '.navigator__tab-link', (event) => {
  event.preventDefault();

  const { currentTarget: $tab } = event;
  const $currentTab = $tab.closest('.navigator__tabs')?.querySelector<HTMLElement>('[aria-selected]');

  if ($currentTab && $tab !== $currentTab) {
    switchTab($currentTab, $tab);
  }
});

on('keydown', '.navigator__tab-link', (event) => {
  const { currentTarget: $tab } = event;

  // Move to open panel
  if (event.key === 'ArrowDown') {
    const panelId = $tab.getAttribute('href') ?? abort();
    const $panel = document.getElementById(panelId.substring(1)) ?? abort();
    moveFocus($panel.querySelector('.navigator__panel-content') ?? abort());

    // Move between tabs
  } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
    const index = elementIndex($tab.parentElement ?? abort());
    const newIndex = event.key === 'ArrowLeft' ? index - 1 : index + 1;

    const $$tab = $tab.closest('.navigator__tabs')?.querySelectorAll<HTMLElement>('.navigator__tab-link');

    if ($$tab && $$tab[newIndex]) {
      switchTab($tab, $$tab[newIndex]);
    }
  }
});

on('click', '.navigator__panel-trigger', (event) => {
  // Stop event here
  event.preventDefault();

  // Get state
  const { currentTarget: $trigger } = event;
  const $panel = $trigger.parentElement ?? abort();
  const $content = $panel.querySelector<HTMLElement>('.navigator__panel-content') ?? abort();
  const $tab = document.getElementById($panel.getAttribute('aria-labelledby') ?? abort()) ?? abort();

  // Get current state
  const isOpen = $trigger.getAttribute('aria-expanded') === 'true';

  // Toggle state
  $trigger.setAttribute('aria-expanded', isOpen ? 'false' : 'true');
  $content.setAttribute('aria-hidden', isOpen ? 'true' : 'false');

  // Actions on opening
  if (!isOpen) {
    // Change tab state
    $tab.setAttribute('aria-selected', 'true');
    $tab.removeAttribute('tabindex');

    // Close others
    $panel.parentElement?.querySelectorAll<HTMLElement>('.navigator__panel').forEach(($siblingPanel) => {
      if ($siblingPanel !== $panel) {
        $siblingPanel.querySelector('.navigator__panel-trigger')?.setAttribute('aria-expanded', 'false');
        $siblingPanel.querySelector('.navigator__panel-content')?.setAttribute('aria-hidden', 'true');
        document
          .getElementById($siblingPanel.getAttribute('aria-labelledby') ?? abort())
          ?.removeAttribute('aria-selected');
        document
          .getElementById($siblingPanel.getAttribute('aria-labelledby') ?? abort())
          ?.setAttribute('tabindex', '-1');
      }
    });

    // Focus to next focusable element in content
    moveFocus($content);
  }
});

whenReady().then(() => {
  if (window.location.hash) {
    const $target = document.getElementById(window.location.hash.substring(1));

    if ($target && $target.closest('.navigator__panel')) {
      const $panel = $target.closest('.navigator__panel') ?? abort();
      const newTabId = $panel.getAttribute('data-tab-id') ?? abort();
      const $newTab = document.getElementById(newTabId) ?? abort();

      const $navigator = $newTab.closest('.navigator') ?? abort();
      const $oldTab = $navigator.querySelector<HTMLElement>('.navigator__tab-link');

      if ($oldTab && $oldTab !== $newTab) {
        switchTab($oldTab, $newTab);
        $newTab.scrollIntoView();
      }
    }
  }
});
