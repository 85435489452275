import { on } from 'delegated-events';
import whenReady from '../../../javascripts/utils/whenReady';
import elementIndex from '../../../javascripts/utils/elementIndex';
import abort from '../../../javascripts/utils/abort';

const moveSlider = ($slider: HTMLElement, value = 0, relative = true) => {
  const currentSlide = parseInt($slider.dataset.currentSlide ?? '1', 10) || 1;
  const $$slide = $slider.querySelectorAll<HTMLElement>('.slider__slide');
  const nextSlide = relative ? currentSlide + value : value;

  // Out of range
  if (nextSlide === 0 || nextSlide > $$slide.length) {
    return;
  }

  // Update scroll track
  const $track = $slider.querySelector('.slider__track') ?? abort();
  const $slide = $$slide[nextSlide - 1];
  $track.scrollLeft = $slide.offsetLeft;
  $slider.dataset.currentSlide = nextSlide.toString();

  // Update buttons
  const $prevButton = $slider.querySelector<HTMLButtonElement>('.slider__navigation-button--prev') ?? abort();
  const $nextButton = $slider.querySelector<HTMLButtonElement>('.slider__navigation-button--next') ?? abort();

  $prevButton.disabled = nextSlide === 1;
  $nextButton.disabled = nextSlide === $$slide.length;

  // Update indicators
  const $$indicator = $slider.querySelectorAll<HTMLButtonElement>('.slider__navigation-indicator');
  $$indicator.forEach(($indicator, index) => {
    $indicator.disabled = index + 1 === nextSlide;
  });
};

whenReady().then(() => {
  const $$track = document.querySelectorAll('.slider__track');
  $$track.forEach(($track) => {
    $track.scrollLeft = 0;
  });
});

window.addEventListener(
  'resize',
  () => {
    const $$slider = document.querySelectorAll<HTMLElement>('.slider');
    $$slider.forEach(($slider) => moveSlider($slider));
  },
  false,
);

on('keydown', '.slider__track', (event) => {
  if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
    const $slider = event.currentTarget.closest<HTMLElement>('.slider');
    if ($slider) {
      moveSlider($slider, event.key === 'ArrowLeft' ? -1 : 1);
    }
  }
});

on('click', '.slider__navigation-button--prev', (event) => {
  const $slider = event.currentTarget.closest<HTMLElement>('.slider');
  if ($slider) {
    moveSlider($slider, -1);
  }
});

on('click', '.slider__navigation-button--next', (event) => {
  const $slider = event.currentTarget.closest<HTMLElement>('.slider');
  if ($slider) {
    moveSlider($slider, 1);
  }
});

on('click', '.slider__navigation-indicator', (event) => {
  const $indicator = event.currentTarget;
  const $slider = $indicator.closest<HTMLElement>('.slider') ?? abort();
  const value = parseInt($indicator.dataset.index ?? '1', 10);

  moveSlider($slider, value, false);
});

on('mousedown', '.slider__slide a', (event) => {
  event.preventDefault();
});

on('focusin', '.slider__slide', (event) => {
  const $slide = event.currentTarget;
  const $slider = $slide.closest<HTMLElement>('.person-slider') ?? abort();
  const value = elementIndex($slide);

  moveSlider($slider, value + 1, false);
});
