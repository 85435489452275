import { on } from 'delegated-events';
import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import elementIndex from '../../../javascripts/utils/elementIndex';
import abort from '../../../javascripts/utils/abort';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

on('click', '.js-gallery', (triggerEvent) => {
  const { currentTarget: $trigger } = triggerEvent;
  const { template } = $trigger.dataset;

  if (!template) {
    return;
  }

  const $gallery = $trigger.closest<HTMLElement>('.gallery') ?? abort();
  const $template = document.querySelector<HTMLTemplateElement>(`#${template}`) ?? abort();
  const $fragment = $template.content.cloneNode(true) as DocumentFragment;

  // Create gallery
  const $overlay = $fragment.querySelector<HTMLElement>('.gallery__overlay') ?? abort();
  const $inner = $overlay.querySelector<HTMLElement>('.gallery__overlay-inner') ?? abort();
  $gallery.appendChild($overlay);

  // Resize image
  const resizeImage = ($image: HTMLElement) => {
    // Set max-width on too large images
    const { width: galleryWidth, height: galleryHeight } = $inner.getBoundingClientRect();
    const $img = $image.querySelector('img');
    const width = $img?.getAttribute('width');
    const height = $img?.getAttribute('height');

    if (width && height) {
      const ratio = parseInt(width, 10) / parseInt(height, 10);
      const fittedRatio = galleryHeight / (galleryWidth / ratio);

      $image.style.maxWidth = `${Math.min(1, fittedRatio) * 100}%`;
    }
  };

  // Get current image
  const currentImage = () => $overlay.querySelector<HTMLElement>('.gallery__image:not(.gallery__image--hidden)') ?? abort();

  // Move image
  const moveImage = (by: number) => {
    // Get all images
    const $$image = $overlay.querySelectorAll<HTMLElement>('.gallery__image');

    // Get current image
    const $currentImage = currentImage();

    // Calculate new index
    const currentPosition = elementIndex($currentImage) + 1;
    let newPosition = currentPosition + by;

    if (newPosition > $$image.length) {
      newPosition %= $$image.length;
    }

    if (newPosition <= 0) {
      newPosition = $$image.length + newPosition;
    }

    // Set direction for animation
    const $nextImage = $$image[newPosition - 1];
    $nextImage.dataset.moveDirection = by > 0 ? 'left' : 'right';

    // Hide old image
    $currentImage.classList.add('gallery__image--hidden');

    // Show next image
    $nextImage.classList.remove('gallery__image--hidden');
    resizeImage($nextImage);

    // Move focus
    invisibleFocus($nextImage);

    // Update position display
    const $position = $overlay.querySelector('.gallery__position');
    if ($position) {
      $position.textContent = `${newPosition}/${$$image.length}`;
    }
  };

  // Create a11y dialog
  const dialog = new A11yDialog($overlay);
  dialog.on('hide', () => {
    enableBodyScroll($overlay);
    invisibleFocus($trigger);
    dialog.destroy();
  });

  // On destroy
  dialog.on('destroy', () => {
    $overlay.remove();
  });

  // On show
  dialog.on('show', () => {
    disableBodyScroll($overlay);
    const $currentImage = currentImage();
    resizeImage($currentImage);
  });

  // On resize
  const resizeOberserver = new ResizeObserver(() => {
    const $currentImage = currentImage();
    resizeImage($currentImage);
  });

  resizeOberserver.observe($overlay);

  // On key press
  $overlay.addEventListener('keydown', (event) => {
    if (event.code === 'ArrowRight') {
      event.preventDefault();
      moveImage(1);
    } else if (event.code === 'ArrowLeft') {
      event.preventDefault();
      moveImage(-1);
    }
  });

  // Add events to navigation buttons
  $overlay.querySelector('[data-action="prev"]')?.addEventListener('click', (event) => {
    event.preventDefault();
    moveImage(-1);
  });

  $overlay.querySelector('[data-action="next"]')?.addEventListener('click', (event) => {
    event.preventDefault();
    moveImage(1);
  });

  // Show gallery
  dialog.show();
});
